<app-loader *ngIf="loading"></app-loader>
<nz-content *ngIf="!loading">
  <div nz-row nzGutter="16" class="content-row">
    <div nz-col [nzSm]="24" [nzMd]="8">
      <div class="inner-content">
        <h2 class="ragnar-heading">Twoje zespoły</h2>
        <nz-divider></nz-divider>
        <nz-table #ownedTeams class="teams-list owned-teams"
          *ngIf="(isAuthenticated$ | async) && (ownedTeams$ | async).length > 0" [nzShowPagination]="false"
          [nzData]="ownedTeams$ | async" [nzScroll]="{ x: '1280px' }">
          <tbody>
            <tr [routerLink]="['/team', data.id]" *ngFor="let data of ownedTeams.data">
              <td class="team-image">
                <img src="assets/images/teams/{{ data.logo }}" />
              </td>
              <td class="team-name">{{ data.name }}</td>
            </tr>
          </tbody>
        </nz-table>
        <button class="add-team-button" *ngIf="isAuthenticated$ | async" [routerLink]="['/team/create']" nz-button
          nzType="primary">
          Stwórz nowy zespół
        </button>
        <p class="ragnar-error" *ngIf="!(isAuthenticated$ | async)">
          Zaloguj się, aby zobaczyć tę sekcję.
        </p>
        <button class="add-team-button" nzTooltipPlacement="topCenter" *ngIf="!(isAuthenticated$ | async)" nz-button
          nzType="primary" disabled>
          Stwórz zespół
        </button>
      </div>
    </div>
    <div nz-col [nzSm]="24" [nzMd]="16">
      <div class="inner-content">
        <h2 class="ragnar-heading">Zespoły, których jesteś kierowcą</h2>
        <nz-divider></nz-divider>
        <p class="ragnar-error" *ngIf="!(isAuthenticated$ | async)">
          Zaloguj się, aby zobaczyć tę sekcję.
        </p>
        <nz-table #belongingTeams *ngIf="isAuthenticated$ | async" class="teams-list belonged-teams"
          [nzShowPagination]="false" [nzData]="belongingTeams$ | async" [nzScroll]="{ x: '1280px' }">
          <thead>
            <tr>
              <th></th>
              <th>Nazwa</th>
              <th>Status</th>
              <th>Od</th>
              <th>Do</th>
              <th>Akcje</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of belongingTeams.data">
              <td class="team-image">
                <img src="assets/images/teams/{{ data.team.logo }}" />
              </td>
              <td class="team-name">{{ data.team.name }}</td>
              <td>{{ resolveStatus(data.proposalStatus) }}</td>
              <td>{{ data.dateStart | date : "dd-MM-yyyy" }}</td>
              <td>
                {{
                data.dateStop != null
                ? (data.dateStop | date : "dd-MM-yyyy")
                : ""
                }}
              </td>
              <td *ngIf="data.proposalStatus == 0">
                <a (click)="acceptProposal(data.id, data.team.id)">Akceptuj</a>
                - <a (click)="declineProposal(data.id, data.team.id)">Odrzuć</a>
              </td>
              <td *ngIf="data.proposalStatus == 1">
                <a (click)="leaveTeam(data.id, data.team.id)">Opuść</a>
              </td>
              <td *ngIf="data.proposalStatus > 1"></td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>
</nz-content>